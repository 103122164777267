<template>
  <!-- 直播课程 -->
  <div class="LiveCourse">
    <div class="LiveCourse—list" v-for="(i,k) in LiveCourseList" :key="k">
      <div>{{ i.className }}</div>
      <div style="display: flex;align-items: center;justify-content: space-between">
        <span>《{{ i.topicName }}》</span>
        <div v-if="type === 2">
          <div class="ydp" v-if="i.studentEvaluation || i.teacherEvaluation ">
            <el-button round size="mini" @click="$message('暂只支持学生通过【小卫在线_APP】查看老师对作品的点评内容哦~')">已点评</el-button>
          </div>
          <div class="cczp" v-else-if="i.success && i.studentEvaluation === false && i.teacherEvaluation === false">
            <el-button type="primary" round size="mini" @click="$router.push({path:'SubmitAssignments',query:{id:i.id,type:2}})">重传作品</el-button>
          </div>
          <div class="tjzp" v-else-if="i.success === false && i.studentEvaluation === false && i.teacherEvaluation === false">
            <el-button type="primary" round size="mini" @click="$router.push({path:'SubmitAssignments',query:{topicUnlockInfoId:i.topicUnlockInfoId,workName:i.topicName,id:i.id,type:2}})">提交作品</el-button>
          </div>
        </div>

      </div>
      <div v-if="type === 1"  style="display: flex;align-items: center;justify-content: space-between">
        <span>开课时间：{{ i.classDateTime }}</span>
        <div class="ydp" v-if="i.studentEvaluation || i.teacherEvaluation ">
          <el-button round size="mini" @click="$message('暂只支持学生通过【小卫在线_APP】查看老师对作品的点评内容哦~')">已点评</el-button>
        </div>
        <div class="cczp" v-else-if="i.success && i.studentEvaluation === false && i.teacherEvaluation === false">
          <el-button type="primary" round size="mini"  @click="$router.push({path:'SubmitAssignments',query:{id:i.id,type:1}})">重传作品</el-button>
        </div>
        <div class="tjzp" v-else-if="i.success === false && i.studentEvaluation === false && i.teacherEvaluation === false">
          <el-button type="primary" round size="mini"  @click="$router.push({path:'SubmitAssignments',query:{courseScheduleId:i.courseScheduleId,workName:i.topicName,id:i.id,type:1}})">提交作品</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveCourse",
  props: {
    //2录播 1直播
    type: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      LiveCourseList: []
    }
  },
  mounted() {
    this.gitApistudentWorkInfo()
  },
  methods: {
    async gitApistudentWorkInfo() {
      let res = await this.$_api('/online/app/studentWork/studentWorkInfo', 'post', {
        type: this.type,
        pageNum: 1,
        pageSize: 100000,
      })
      this.LiveCourseList = res.dataList

    },
  },
}
</script>

<style lang="scss" scoped>
.LiveCourse {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .LiveCourse—list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 390px;
    height: 123px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 30px;
    margin-bottom: 20px;
    text-align: left;
    padding: 20px 30px;
    box-sizing: border-box;

    > :nth-child(1) {
      font-size: 16px;
      font-weight: bold;
      width: 165px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.ydp ::v-deep .el-button {
  border: 1px solid #5B95FB;

  > span {
    color: #5B95FB !important;
  }
}

.cczp ::v-deep .el-button--primary {
  background-color: #01D4A6;
  border-color: #01D4A6;

  > span {
    color: #FFFFFF !important;
  }
}

.tjzp ::v-deep .el-button--primary {
  background-color: #E85525;
  border-color: #E85525;

  > span {
    color: #FFFFFF !important;
  }
}
</style>