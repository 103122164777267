<template>
  <!-- 我的作业 -->
  <div class="MyTask page over-flow">
    <route-back/>
    <live-title title="我的作业"  :url="2" />
    <div class="MyTask-list">
      <el-tabs v-model="activeName">
        <el-tab-pane label="直播课程" name="first">
          <live-course></live-course>
        </el-tab-pane>
        <el-tab-pane label="录播课程" name="second">
          <live-course :type="2"></live-course>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import RouteBack from "@/components/RouteBack";
import LiveTitle from "@/components/LiveTitle";
import LiveCourse from "@/components/LiveCourse";
export default {
  name: "MyTask",
  components: {LiveCourse, LiveTitle, RouteBack},
  data(){
    return {
      activeName:'first'
    }
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
.MyTask{
  position: relative;
  background: url("../assets/image/bg-zb.png") no-repeat;
  background-size: 100% 100%;
  overflow-y: hidden;
  .MyTask-list{
    background: url("../assets/image/bg_zuoy(1).png") no-repeat;
    background-size: 100% 100%;
    height: 500px;
    width: 901px;
    position: absolute;
    left: 86px;
    top: 133px;
    padding: 21px 40px;
    box-sizing: border-box;
  }
}
::v-deep .el-tabs__nav-wrap::after,.el-tabs__nav-wrap::after{
  background-color: transparent !important;
}
::v-deep .el-tabs__item.is-active{
  color: #FFFFFF;
  font-weight: bold;
}
::v-deep .el-tabs__active-bar{
  background-color: #FFFFFF;
}
::v-deep .el-tabs__item:hover{
  color: #FFFFFF;
}
::v-deep .el-tabs__item{
  color: #FFFFFF;
}
::v-deep .el-tabs__content{
  height: 410px;
  overflow-y: auto;
}
::v-deep .el-tabs__content::-webkit-scrollbar {
  display:none;
 }

</style>